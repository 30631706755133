<template>
  <div>
    <div class="h-100 bg-animation">
      <div class="d-flex h-100 justify-content-center align-items-center">
        <b-col md="8" class="mx-auto app-login-box">
          <apexchart ref="donut" width="650" type="donut" :options="chartOptions" :series="series"></apexchart>
          <br>
        <div class="text-center">
          <h4 >
            <div>{{pollTitle}}</div>
          </h4>
          <h6 class="mb-3 ml-3 mr3">
            <span >{{pollCount}}</span>
          </h6>
        </div>
        </b-col>
      </div>
    </div>
  </div>
</template>

<script>
import VueElementLoading from "vue-element-loading";
import swal from "sweetalert";
import VueApexCharts from "vue-apexcharts";
import PollsService from "@/services/polls.service";

export default {
  name: 'PollsResultsStandaloneDonut',
  components: {
    VueElementLoading,
    apexchart: VueApexCharts
  },
  props:{
    pollId: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {

      chartOptions: {
        labels: [],
        plotOptions: {
          pie: {
            size: 200
          }
        }
      },
      series: [],
      showLoading: false,
      pollTitle:"",
      pollCount:""
    };
  },
  computed: {

  },
  created() {
    //
  },
  mounted () {
    window.setInterval(() => {
      this.getSessionLatestMessages()
    }, 60000)

    this.getPollStats()
  },
  methods: {

    getPollStats(pollId)
    {
      this.showLoading = true
      PollsService.getPollStats(this.pollId).then((response) =>
      {
        response

        this.pollTitle = response.data.response.poll_title;
        this.pollCount = "Общо гласували: "+response.data.response.total_answered;
        this.answers = response.data.response.results;
        this.series = []
        this.labels = []
        for (const answer of this.answers) {
          this.labels.push(answer.title)
          this.series.push(answer.count)
        }
        this.chartOptions = {
          labels: this.labels,
        };
        this.$refs.donut.updateOptions()
        this.$root.$emit('bv::toggle::modal', 'modal-stats', '#btnToggle')
      })

      this.showLoading = false
    },
  }
};
</script>